.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.even-row {
  background-color: white;
  /* Set the background color for even rows */
}

/* Style for odd rows */
.odd-row {
  background-color: #F5DDFB2B;
  /* Set the background color for odd rows */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transaction-header .MuiDataGrid-columnHeaderTitle {
  color: #737274;
  font-family: Roboto;
  font-size: 0.9375rem;
  font-weight: 500;
}


